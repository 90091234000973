import { OAuthRequestDialog} from "@backstage/core-components"

import React from "react"
import { useApi, configApiRef } from '@backstage/core-plugin-api';

export const OAuthRequestDialogWrapper = () => {
  const configApi = useApi(configApiRef);
  const onRuntime = configApi.getOptionalBoolean('ymirConfig.isOnRuntime') ?? false

  return !onRuntime && <OAuthRequestDialog />
}
